import { getGatsbyImageData } from 'gatsby-source-sanity'
import clientConfig from '../../client-config'
import { imageUrlFor } from '../lib/image-url'

export function cn(...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current
}

export function validUrl(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText(blocks) {
  if (!blocks) {
    return ''
  }
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}

// browser
export function isBrowser() {
  return typeof window !== 'undefined'
}

export function isRetina() {
  if (isBrowser()) {
    return window.devicePixelRatio > 1
  }
}

// image
export function getFluidProps(image, maxWidth) {
  return getGatsbyImageData(image.asset._id, { maxWidth: maxWidth }, clientConfig.sanity)
}

export function buildSrcSetUrl(width) {
  return imageUrlFor(buildImageObj(image))
    .auto('format')
    .width(width)
    .height()
    .quality(_quality)
    .url()
}

export function buildSrcSetStr(image, widths = []) {
  const arr = widths.map(width => {
    const src = imageUrlFor(buildImageObj(image))
      .auto('format')
      .width(width * 1.5)
      .height()
      .url()
    return `${src} ${width}w`
  })
  return arr.join(', ')
}

export function buildPlaceholderUrl(image, width) {
  return imageUrlFor(buildImageObj(image))
    .auto('format')
    .width(width)
    .height()
    .quality(1)
    .url()
}

export function firstLetterUppercased(content) {
  return content.charAt(0).toUpperCase()
}
